/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'
import auth from './auth/authService'

import firebase from 'firebase/app'
import 'firebase/auth'

Vue.use(Router)

const ifAuthenticated = async (to, from, next) => {
  if (localStorage.getItem('accessToken') && (localStorage.getItem('accessToken') !== null) && localStorage.getItem('accessToken').length) {
    next()
    return
  }
  next('/pages/login')
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [

    {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: '/',
          redirect: '/dashboard'
        },
        {
          path: '/dashboard',
          name: 'dashboard',
          beforeEnter: ifAuthenticated,
          component: () => import('./views_moozimed/dashboard/dashboard.vue'),
          meta: {
            rule: 'editor'
          }
        },

        // =============================================================================
        // ------- Start MooZiSupport Application Routes -------------------------------
        // =============================================================================


        {
          path: '/Utilisateurs',
          beforeEnter: ifAuthenticated,
          name: 'users',
          component: () => import('./views_moozimed/users/DataListListView.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/practitioners',
          beforeEnter: ifAuthenticated,
          name: 'practitioners',
          component: () => import('./views_moozimed/doctors/DataListdoctors.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/cities',
          beforeEnter: ifAuthenticated,
          name: 'cities',
          component: () => import('./views_moozimed/cities/DataListCities'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/diseases',
          beforeEnter: ifAuthenticated,
          name: 'diseases',
          component: () => import('./views_moozimed/diseases/DatalistDiseases'),
          meta: {
            rule: 'editor'
          }
        },

        {
          path: '/allergies',
          beforeEnter: ifAuthenticated,
          name: 'allergies',
          component: () => import('./views_moozimed/allergies/DataListAllergies'),
          meta: {
            rule: 'editor'
          }
        },

        {
          path: '/operations',
          beforeEnter: ifAuthenticated,
          name: 'operations',
          component: () => import('./views_moozimed/operations/DataListOperations'),
          meta: {
            rule: 'editor'
          }
        },

        {
          path: '/occupations',
          beforeEnter: ifAuthenticated,
          name: 'occupations',
          component: () => import('./views_moozimed/occupations/DataListOccupations'),
          meta: {
            rule: 'editor'
          }
        },
        
        {
          path: '/partnerTypes',
          beforeEnter: ifAuthenticated,
          name: 'partnerTypes',
          component: () => import('./views_moozimed/partnerTypes/DataListpartnerTypes'),
          meta: {
            rule: 'editor'
          }
        },

        {
          path: '/partners',
          beforeEnter: ifAuthenticated,
          name: 'partners',
          component: () => import('./views_moozimed/partners/DataListpartner'),
          meta: {
            rule: 'editor'
          }
        },

        {
          path: '/assurances',
          beforeEnter: ifAuthenticated,
          name: 'assurances',
          component: () => import('./views_moozimed/partners/DataListAssurances'),
          meta: {
            rule: 'editor'
          }
        },

        {
          path: '/pharmacies',
          beforeEnter: ifAuthenticated,
          name: 'pharmacies',
          component: () => import('./views_moozimed/partners/DataListpharmacies.vue'),
          meta: {
            rule: 'editor'
          }
        },

        /*  {
          path: '/appointments',
          beforeEnter: ifAuthenticated,
          name: 'appointments',
          component: () => import('./views_moozimed/appointments/SimpleCalendar.vue'),
          meta: {
            rule: 'editor',
            no_scroll: true
          }
        }, */
        {
          path: '/appointments_full',
          beforeEnter: ifAuthenticated,
          name: 'appointments_full',
          component: () => import('./views_moozimed/appointments/Appointments.vue'),
          meta: {
            rule: 'editor',
            no_scroll: true
          }
        },
        {
          path: '/appointments_day',
          beforeEnter: ifAuthenticated,
          name: 'appointments_day',
          component: () => import('./views_moozimed/appointments/Appointments_day.vue'),
          meta: {
            rule: 'editor',
            no_scroll: true
          }
        },
        {
          path: '/appointments_week',
          beforeEnter: ifAuthenticated,
          name: 'appointments_day',
          component: () => import('./views_moozimed/appointments/Appointments_week.vue'),
          meta: {
            rule: 'editor',
            no_scroll: true
          }
        },
        {
          path: '/appointments_month',
          beforeEnter: ifAuthenticated,
          name: 'appointments_month',
          component: () => import('./views_moozimed/appointments/Appointments_month.vue'),
          meta: {
            rule: 'editor',
            no_scroll: true
          }
        },
        {
          path: '/appointments_upcomming',
          beforeEnter: ifAuthenticated,
          name: 'appointments_upcomming',
          component: () => import('./views_moozimed/appointments/Appointments_upcomming.vue'),
          meta: {
            rule: 'editor',
            no_scroll: true
          }
        },
        {
          path: '/appointments_pass',
          beforeEnter: ifAuthenticated,
          name: 'appointments_pass',
          component: () => import('./views_moozimed/appointments/Appointments_upcomming.vue'),
          meta: {
            rule: 'editor',
            no_scroll: true
          }
        },
        /*  {
          path: '/appointments_list',
          beforeEnter: ifAuthenticated,
          name: 'appointments_list',
          component: () => import('./views_moozimed/appointments/DataListAppointments.vue'),
          meta: {
            rule: 'editor',
            no_scroll: true
          }
        }, */
        {
          path: '/patients',
          beforeEnter: ifAuthenticated,
          name: 'patients',
          component: () => import('./views_moozimed/patients/DataListpatientsWizard.vue'),
          meta: {
            rule: 'editor',
            no_scroll: true
          }
        },
        /* 
        {
          path: '/patients',
          beforeEnter: ifAuthenticated,
          name: 'patients',
          component: () => import('./views_moozimed/patients/DataListpatients.vue'),
          meta: {
            rule: 'editor',
            no_scroll: true
          }
        }, */
        /* {
          path: '/FormWizardValidation',
          beforeEnter: ifAuthenticated,
          name: 'FormWizardValidation',
          component: () => import('./views_moozimed/patients/FormWizardValidation.vue'),
          meta: {
            rule: 'editor',
            no_scroll: true
          }
        }, */
        {
          path: '/previousTreatments',
          beforeEnter: ifAuthenticated,
          name: 'previousTreatments',
          component: () => import('./views_moozimed/previousTreatment/DataListpreviousTreatment'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/roles',
          beforeEnter: ifAuthenticated,
          name: 'roles',
          component: () => import('./views_moozimed/roles/Datalistroles'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/TypeActes',
          beforeEnter: ifAuthenticated,
          name: 'TypeActes',
          component: () => import('./views_moozimed/typeActes/DatalistTypeActes.vue'),
          meta: {
            rule: 'editor'
          }
        },

        {
          path: '/actes',
          beforeEnter: ifAuthenticated,
          name: 'actes',
          component: () => import('./views_moozimed/actes/DatalistActes.vue'),
          meta: {
            rule: 'editor'
          }
        },

        {
          path: '/treatments',
          beforeEnter: ifAuthenticated,
          name: 'treatments',
          component: () => import('./views_moozimed/treatments/DatalistTreatments.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/stockTypes',
          beforeEnter: ifAuthenticated,
          name: 'stockTypes',
          component: () => import('./views_moozimed/approvisionnements_Type/DataListapprovisionnementsType.vue'),
          meta: {
            rule: 'editor'
          }
        },

        {
          path: '/stockTypes',
          beforeEnter: ifAuthenticated,
          name: 'stockTypes',
          component: () => import('./views_moozimed/approvisionnements_Type/DataListapprovisionnementsType.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/stocks',
          beforeEnter: ifAuthenticated,
          name: 'stocks',
          component: () => import('./views_moozimed/approvisionnements/DataListapprovisionnements.vue'),
          meta: {
            rule: 'editor'
          }
        },

        {
          path: '/materials',
          beforeEnter: ifAuthenticated,
          name: 'materials',
          component: () => import('./views_moozimed/approvisionnements_list/DataListapprovisionnements_ListMate.vue'),
          meta: {
            rule: 'editor'
          }
        },

        {
          path: '/products',
          beforeEnter: ifAuthenticated,
          name: 'products',
          component: () => import('./views_moozimed/approvisionnements_list/DataListapprovisionnements_listProd.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/sickLeaves',
          beforeEnter: ifAuthenticated,
          name: 'sickLeaves',
          component: () => import('./views_moozimed/sickLeaves/DatalistSickleaves.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/prescriptions',
          beforeEnter: ifAuthenticated,
          name: 'prescriptions',
          component: () => import('./views_moozimed/prescriptions/DataListprescriptions.vue'),
          meta: {
            rule: 'editor'
          }
        },

        {
          path: '/InvoicePrescriptions',
          beforeEnter: ifAuthenticated,
          name: 'InvoicePrescriptions',
          component: () => import('./views_moozimed/prescriptions/InvoicePrescriptions.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/InvoiceTreatements',
          beforeEnter: ifAuthenticated,
          name: 'InvoiceTreatements',
          component: () => import('./views_moozimed/treatments/InvoiceTreatments.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/enters',
          beforeEnter: ifAuthenticated,
          name: 'enters',
          component: () => import('./views_moozimed/approvisionnements_list/Datalistapprovisionnements_enters.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/outs',
          beforeEnter: ifAuthenticated,
          name: 'outs',
          component: () => import('./views_moozimed/approvisionnements_list/Datalistapprovisionnements_outs.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/InvoiceSickleaves',
          beforeEnter: ifAuthenticated,
          name: 'InvoiceSickleaves',
          component: () => import('./views_moozimed/sickLeaves/InvoiceSickleaves.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/bills_paid',
          beforeEnter: ifAuthenticated,
          name: 'bills_paid',
          component: () => import('./views_moozimed/paiements/DataListFacture_payé.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/bills_to_settle',
          beforeEnter: ifAuthenticated,
          name: 'bills_to_settle',
          component: () => import('./views_moozimed/paiements/DataListFacture_Tiers.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/InvoiceBills',
          beforeEnter: ifAuthenticated,
          name: 'InvoiceBills',
          component: () => import('./views_moozimed/paiements/InvoiceFacture.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/bills_not_paid',
          beforeEnter: ifAuthenticated,
          name: 'bills_not_paid',
          component: () => import('./views_moozimed/paiements/DataListFacture_impayé.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/Profil',
          name: 'page-Profil',
          beforeEnter: ifAuthenticated,
          component: () => import('./views/pages/login/MonProfil'),
          meta: {
            rule: 'editor'
          }
        },

        {
          path: '/pages/Profile',
          name: 'page-Profile',
          beforeEnter: ifAuthenticated,
          component: () => import('./views/pages/login/MonProfile'),
          meta: {
            rule: 'editor'
          }
        }

      ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('./layouts/full-page/FullPage.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: '/callback',
          name: 'auth-callback',
          component: () => import('./views/Callback.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/login',
          name: 'page-login',
          component: () => import('./views/pages/login/Login.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/Changelogin',
          name: 'page-Changelogin',
          component: () => import('./views/pages/login/Changelogin'),
          meta: {
            rule: 'editor'
          }
        },


        {
          path: '/pages/register',
          name: 'page-register',
          component: () => import('./views/pages/register/Register.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/forgot-password',
          name: 'page-forgot-password',
          component: () => import('./views/pages/ForgotPassword.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/reset-password',
          name: 'page-reset-password',
          component: () => import('./views/pages/ResetPassword.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/lock-screen',
          name: 'page-lock-screen',
          component: () => import('./views/pages/LockScreen.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/comingsoon',
          name: 'page-coming-soon',
          component: () => import('./views/pages/ComingSoon.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/error-404',
          name: 'page-error-404',
          component: () => import('./views/pages/Error404.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/error-500',
          name: 'page-error-500',
          component: () => import('./views/pages/Error500.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/not-authorized',
          name: 'page-not-authorized',
          component: () => import('./views/pages/NotAuthorized.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/maintenance',
          name: 'page-maintenance',
          component: () => import('./views/pages/Maintenance.vue'),
          meta: {
            rule: 'editor'
          }
        }
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/pages/error-404'
    }
  ]
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, from, next) => {
  firebase.auth().onAuthStateChanged(() => {

    // get firebase current user
    const firebaseCurrentUser = firebase.auth().currentUser

    // If auth required, check login. If login fails redirect to login page
    if (to.meta.authRequired) {
      if (!(auth.isAuthenticated() || firebaseCurrentUser)) {
        router.push({ path: '/pages/login', query: { to: to.path } })
      }
    }

    return next()
    // Specify the current path as the customState parameter, meaning it
    // will be returned to the application after auth
    // auth.login({ target: to.path });

  })

})

export default router
